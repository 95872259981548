import axios from "axios";
import React, {useState,useEffect} from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

const endpoint = "https://alzaripsa.com/api/user"

const EditUser = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const {id} = useParams()
    const update = async (e) => {
        e.preventDefault()
        await axios.put(`${endpoint}/${id}`, {
            name: name, password:password
        })
        navigate('/list')
    }

    useEffect(() => {
        const getUserById = async () => {
            const response = await axios.get(`${endpoint}/${id}`)
            setName(response.data.name)
            setEmail(response.data.email)
            setPassword(response.data.password)
        }
        getUserById()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return(
        <div>
        <h3>Editar Usuario</h3>
        <form action="" onSubmit={update}>
            <div className='mb-3'>
                <label htmlFor="name" className='form-label'>Nombre</label>
                <input type="text" name='name' value={name} onChange={(e)=>setName(e.target.value)} className='form-control'/>
            </div>
            <div className='mb-3'>
                <label htmlFor="email" className='form-label'>Email</label>
                <input type="email" disabled name="email" value={email} onChange={(e)=>setEmail(e.target.value)} className='form-control'/>
            </div>
            <div className='mb-3'>
                <label htmlFor="pass" className='form-label'>Clave</label>
                <input type="password" name='pass' value={password} onChange={(e)=>setPassword(e.target.value)} className='form-control'/>
            </div>
            <button type="submit" className='btn btn-primary'>Actualizar</button>
        </form>
        <Link to="/" className="btn btn-success mb-2 btnBoxInv">
            Home
          </Link>
    </div>
    )
}

export default EditUser