import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import ShowUsers from './components/ShowUsers';
import CreateUser from './components/CreateUser';
import EditUser from './components/EditUser';
import LoginForm from './components/LoginForm';
import { AuthProvider } from './components/AuthContext';
function App() {
  return (
    <div className="App">

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/list' element={<ShowUsers/>}/>
        <Route path='/create' element={<CreateUser/>}/>
        <Route path='/edit/:id' element={<EditUser/>}/>
        <Route path='/login' element={<LoginForm />}/>
      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
