import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div>
      <header className="App-header">
        <div className="boxBtn">
          <Link to="/login" className="btn btn-primary mb-2 btnBoxInv">
            Inicio de sesión
          </Link>
          <Link to="/create" className="btn btn-primary mb-2 btnBoxInv">
            Registro
          </Link>
          <Link to="/list" className="btn btn-primary mb-2 btnBoxInv">
            Editar perfil
          </Link>
        </div>
      </header>
    </div>
  )
}

export default Home;