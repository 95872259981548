import React, {useState} from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'

const endpoint = "https://alzaripsa.com/api/user";

export const CreateUser = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()

    const store = (e) =>{
        e.preventDefault()
        axios.post(endpoint, {name: name, email: email, password: password})
        navigate('/list')
    }
  return (
    <div>
        <h3>Crear Usuario</h3>
        <form action="" onSubmit={store}>
            <div className='mb-3'>
                <label htmlFor="name" className='form-label'>Nombre</label>
                <input type="text" name='name' value={name} onChange={(e)=>setName(e.target.value)} className='form-control'/>
            </div>
            <div className='mb-3'>
                <label htmlFor="email" className='form-label'>Email</label>
                <input type="email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} className='form-control'/>
            </div>
            <div className='mb-3'>
                <label htmlFor="pass" className='form-label'>Clave</label>
                <input type="password" name='pass' value={password} onChange={(e)=>setPassword(e.target.value)} className='form-control'/>
            </div>
            <button type="submit" className='btn btn-primary'>Registrar</button>
        </form>
        <Link to="/" className="btn btn-success mb-2 btnBoxInv">
            Home
          </Link>
    </div>
  )
}

export default CreateUser