import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const endpoint = "https://alzaripsa.com/api";
const ShowUsers = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []);
  const getAllUsers = async () => {
    const response = await axios.get(`${endpoint}/users`);
    setUsers(response.data)
  };
  console.log("Data recibida "+getAllUsers());
  return (
    <div>
      <h1>Listado de usuarios</h1>
    </div>
  );
};

export default ShowUsers;
