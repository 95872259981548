import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const endpoint = "https://alzaripsa.com/api";

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async (e) =>{
    e.preventDefault();
    function isKeyExists(obj, key){
      return obj.hasOwnProperty(key);
    }
    try{
      const response = await axios.post(`${endpoint}/login`, { email, password });
      if(isKeyExists(response.data, "id")){
        setIsLoggedIn(true);
        navigate("/");
        alert("Inicio de sesión exitoso");
      }else{
        alert("Datos ingresados incorrectos.");
      }
    }catch (error){
      console.error(error);
    }
  }

  return (
    <div style={{'marginTop': 5+'%'}}>
     <section className="vh-100">
        <div className="container-fluid h-custom">
          <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start col-md-9">
                  <h1 id='enter' className="lead fw-normal col-12">Iniciar sesión</h1>
          </div>
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid"
                alt="Ejemplo imagen"
              />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form onSubmit={login}>
                <div className="mb-4">
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <input type="email" name="email" id="email" required className="form-control form-control-lg inputText" placeholder="Ingresar un email valido"  value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="password">Contraseña</label>
                    <input type="password" name="password" id="password" required className="form-control form-control-lg inputText" placeholder="Ingresa contraseña" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                </div>
                <div className="text-center text-lg-start mt-4 pt-2">
                  <button type="submit" className="btn btn-primary btn-lg inputText" style={{'paddingLeft': 2.5+'rem','paddingRight': 2.5+'rem'}}>Ingresar</button>
                  <p className="small fw-bold mt-2 pt-1 mb-0">
                    ¿No tiene cuenta?{" "}
                    <Link to="/create" className="link-danger">Registrarse</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoginForm;